// src/styled/Animations.js
import { css } from 'styled-components';

export const fadeIn = css`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const slideIn = css`
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
