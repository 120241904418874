// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';
import ContactUs from './ContactUS'
import FooterComponent from './Footer';
import GlobalStyles from './styled/GlobalStyles';

const App = () => {
  return (
    <Router>
      <div>
        <GlobalStyles />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/contact" element={<ContactUs/>} />
        </Routes>
        <FooterComponent />
      </div>
    </Router>
  );
};

//          <Route path="/services" element={<Services/>} />

export default App;
