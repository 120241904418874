import React from 'react';
import Form from './Form';

const ContactUs = () => {
  return (
    <div>
      <section className="contact">
        <div className="container">
          <h2>Contact Us</h2>
          <p>Ready to start your data-driven journey? Reach out to us for expert consultation and tailored solutions.</p>
          {/* Add a contact form or contact details here */}
        </div>
      </section>
      <Form/>
    </div>
  );
};

export default ContactUs;