// src/styled/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }

  nav {
    background-color: #333;
    color: white;
    padding: 1em;

    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-around;

      li {
        margin: 0;
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .hero {
    background: linear-gradient(to bottom, #4286f4, #1c68ff);
    color: white;
    text-align: center;
    padding: 3em 0;

    h1 {
      font-size: 2.5em;
    }

    p {
      font-size: 1.2em;
      margin-top: 1em;
    }
  }

  /* About Us Section */
  .about-us {
    background-color: #f5f5f5;
    padding: 3em 0;

    h2 {
      text-align: center;
      margin-bottom: 1em;
    }

    p {
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  /* Services Section */
  .services {
    padding: 3em 0;
    text-align: center;

    h2 {
      margin-bottom: 1em;
    }

    .service-cards {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .service-card {
      background-color: #fff;
      padding: 2em;
      margin: 1em;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 1.5em;
        margin-bottom: 0.5em;
      }

      p {
        color: #555;
      }
    }
  }

  /* Why Choose Us Section */
  .why-choose-us {
    background-color: #f5f5f5;
    padding: 3em 0;

    h2 {
      text-align: center;
      margin-bottom: 1em;
    }

    .choose-us-grid {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .choose-us-item {
      text-align: center;
      max-width: 300px;
      margin: 1em;

      h3 {
        font-size: 1.5em;
        margin-bottom: 0.5em;
      }

      p {
        color: #555;
      }
    }
  }

  /* Contact Section */
  .contact {
    background: linear-gradient(to bottom, #4286f4, #1c68ff);
    color: white;
    text-align: center;
    padding: 3em 0;

    h2 {
      margin-bottom: 1em;
    }

    p {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  /* Footer Section */
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1em;

    p {
      margin: 0;
    }
  }

  .full-screen {
    height: 60vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
