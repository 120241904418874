// src/components/Form.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Section } from './styled/Layout';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Initialize EmailJS parameters
    const serviceID = 'service_mjloecs';
    const templateID = 'template_u7v8y1m';
    const userID = 'xS1IQ6iOj15GucQXK';

    // EmailJS form data preparation
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      to_email: 'novawicktehnologies@gmail.com', // This can be set in the EmailJS email template directly
    };


    emailjs.send(serviceID, templateID, templateParams, userID)
      .then(response => {
        console.log('Email successfully sent!', response);
        alert('Your information is sent successfully');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        // Optionally reset form or give user feedback
      })
      .catch(err => console.error('Failed to send email. Error:', err));
  };

  return (
    <Container>
      <Section>
        <form className="full-screen" onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required style={{ width: '100%', marginBottom: '10px', padding: '8px' }} />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required style={{ width: '100%', marginBottom: '10px', padding: '8px' }} />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required style={{ width: '100%', marginBottom: '10px', padding: '8px', resize: 'vertical' }} />

          <button type="submit" style={{ background: '#007bff', color: 'white', padding: '10px', cursor: 'pointer', border: 'none', borderRadius: '5px' }}>
            Submit
          </button>
        </form>
      </Section>
    </Container>
  );
};

export default Form;
