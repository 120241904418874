// src/components/Services.js
import React from 'react';
import { Container, Section } from './styled/Layout';
import { fadeIn } from './styled/Animations';

const Services = () => {
  return (
    <Container>
      <Section>
      <h2>Our Services</h2>
      <p>We offer a range of IT consulting services to meet your business needs.</p>
    </Section>
    </Container>
  );
};

export default Services;
