import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from './nwlogo.png';

const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .logo {
    img {
      width: 200px;
      height: auto;
      border-radius: 10px;
    }
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      padding: 10px 15px;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 1.2em;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #1052cc;
      }
    }
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <div className="logo">
        <img src={logo} alt="NovaWick Technologies Logo" />
      </div>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
      </ul>
    </StyledNavbar>
  );
};

export default Navbar;
