import React from 'react';
import { Container, Section } from './styled/Layout';
import { fadeIn } from './styled/Animations';
import bcg from './background.jpg';

const Home = () => {
  return (
    <div style={{ fontFamily: 'Roboto, sans-serif' }}>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Your Vision, Our Expertise</h1>
          <p>Empowering your projects with innovative solutions in data analytics, cloud computing, and Azure DevOps.</p>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about-us">
        <div className="container">
          <h2>About Us</h2>
          <p>
            Embark on a transformative journey with NovaWick Technologies, where our dynamic team is committed to elevating your projects through the creation of innovative solutions in the realms of Data Analytics, Cloud Computing, and Azure DevOps. With a focus on cutting-edge technologies and a passion for excellence, we bring a wealth of expertise to empower your endeavors, ensuring they reach new heights of efficiency and success.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="services">
        <div className="container">
          <h2>Our Services</h2>
          <div className="service-cards">
            {/* Data Analytics Card */}
            <div className="service-card">
              <h3>Data Analytics</h3>
              <p>Uncover actionable insights and drive informed decisions with our advanced data analytics solutions.</p>
            </div>
            {/* Cloud Solutions Card */}
            <div className="service-card">
              <h3>Cloud Solutions</h3>
              <p>Maximize scalability and efficiency by harnessing the power of cloud technologies tailored to your business needs.</p>
            </div>
            {/* Azure DevOps Card */}
            <div className="service-card">
              <h3>Azure DevOps</h3>
              <p>Streamline your development processes and ensure seamless deployment with our Azure DevOps solutions.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <div className="container">
          <h2>Why Choose Us?</h2>
          <div className="choose-us-grid">
            {/* Collaborative Approach */}
            <div className="choose-us-item">
              <h3>Collaborative Approach</h3>
              <p>Engage with our experts throughout the development journey, ensuring your vision becomes a reality.</p>
            </div>
            {/* Cutting-edge Technologies */}
            <div className="choose-us-item">
              <h3>Cutting-edge Technologies</h3>
              <p>Stay ahead with our proficiency in the latest technologies, delivering solutions that stand out in the market.</p>
            </div>
            {/* Quality Assurance */}
            <div className="choose-us-item">
              <h3>Quality Assurance</h3>
              <p>We take pride in delivering high-quality solutions with clean code and rigorous testing methodologies.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
