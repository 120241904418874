// src/components/Footer.js
import React from 'react';
import { Footer } from './styled/Layout';
import { Container, Section } from './styled/Layout';

const FooterComponent = () => {
  return (
    <Footer>
      <Container>
        <p>&copy; 2023 NovaWick Technologies. All rights reserved.</p>
        <p>
          Contact us at <a href="mailto:hr@novawick.com">hr@novawick.com</a>
        </p>
      </Container>
    </Footer>
  );
};

export default FooterComponent;
